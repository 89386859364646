import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from './plugins/font-awesome'
import Roulette from './components/games/Roulette.vue';
import Keno from './components/games/Keno.vue';
import Lucky6 from './components/games/Lucky6.vue';
import Race6 from './components/games/Race6.vue';
import Race62 from './components/games/Race62.vue';
import Race7 from './components/games/Race7.vue';
import Race8 from './components/games/Race8.vue';
import Fight from './components/games/Fight.vue';
import Lotto7 from './components/games/Lotto7.vue';
import Lotto6 from './components/games/Lotto6.vue';
import Lotto5 from './components/games/Lotto5.vue';
import VueCountdown from '@chenfengyuan/vue-countdown';
import i18n from './i18n'

let app=createApp(App)
app.use(router)
app.use(store)
app.use(i18n)
app.component("font-awesome-icon", FontAwesomeIcon)
app.component(VueCountdown.name, VueCountdown)
app.component("Roulette", Roulette)
app.component("Keno", Keno)
app.component("Lucky6", Lucky6)
app.component("Race6", Race6)
app.component("Race62", Race62)
app.component("Race7", Race7)
app.component("Race8", Race8)
app.component("Fight", Fight)
app.component("Lotto7", Lotto7)
app.component("Lotto6", Lotto6)
app.component("Lotto5", Lotto5)
app.mount("#app");
