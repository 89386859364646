<template>
  <div class="dogs6-grid">
    <div v-if="bet_type === 'winner'" class="dogs-section">
      <div class="dogs6-grid-lg" v-if="Object.keys(main_bets).length > 0">
        <div class="sector_none bet-cell">
          <span class="black_text">{{$t('cashier.race.prizes_label')}}</span> <Toggle v-model="bet_switch" class="toggle-blue" :offLabel="$t('cashier.race.no_label')" :onLabel="$t('cashier.race.yes_label')"></Toggle>
        </div>
        <div class="sector_win_label dogs_cell_gradient bet-cell">
          <div class="dogs_table_label_cell">
            <div class="icon_star">
              <i class="fa fa-star-o"><font-awesome-icon :icon="['far', 'star']" /></i>
            </div>
            <div class="dogs_table_label_text txt-color-blueDark">
              <strong>{{$t('cashier.race.'+checkWinnerBetsObj('win_label'))}}</strong>
            </div>
          </div>
        </div>
        <div class="sector_place_label dogs_cell_gradient bet-cell">
          <div class="dogs_table_label_cell">
            <div class="icon_star">
              <i class="fa fa-star-o"><font-awesome-icon :icon="['far', 'star']" /></i>
              <i class="fa fa-star-o"><font-awesome-icon :icon="['far', 'star']" /></i>
            </div>
            <div class="dogs_table_label_text txt-color-blueDark">
              <strong>{{$t('cashier.race.'+checkWinnerBetsObj('place_label'))}}</strong>
            </div>
          </div>
        </div>
        <div class="sector_show_label dogs_cell_gradient bet-cell">
          <div class="dogs_table_label_cell">
            <div class="icon_star">
              <i class="fa fa-star-o"><font-awesome-icon :icon="['far', 'star']" /></i>
              <i class="fa fa-star-o"><font-awesome-icon :icon="['far', 'star']" /></i>
              <i class="fa fa-star-o"><font-awesome-icon :icon="['far', 'star']" /></i>
            </div>
            <div class="dogs_table_label_text txt-color-blueDark">
              <strong>{{$t('cashier.race.'+checkWinnerBetsObj('show_label'))}}</strong>
            </div>
          </div>
        </div>
        <div v-for="item in 7" :key="item" :class="'sector_win_'+item" class="bet-cell">
          <button class="roulette-btn-base" :class="checkIsSelected(checkWinnerBets('win_')+item)" :disabled="race7_bets_obj.main_enabled === 'f' || (bet_switch === true && main_bets['p'+item]['win_odds'] === '-') || (bet_switch !== true && main_bets['p'+item]['no_win_odds'] === '-')" @click="getOneBet(checkWinnerBets('win_')+item, main_bets['p'+item][checkWinnerBetsObj('win_odds')])">
            <i v-if="race7_bets_obj.main_enabled === 'f' || (bet_switch === true && main_bets['p'+item]['win_odds'] === '-') || (bet_switch !== true && main_bets['p'+item]['no_win_odds'] === '-')" class="fa fa-star-o txt-color-blueLight"><font-awesome-icon :icon="['fas', 'lock']" /></i>
            <span v-if="race7_bets_obj.main_enabled === 't' && bet_switch === true && main_bets['p'+item]['win_odds'] !== '-'">{{convert_coef(main_bets['p'+item]['win_odds'], race7_bets_obj.coef_type)}}</span>
            <span v-if="race7_bets_obj.main_enabled === 't' && bet_switch !== true && main_bets['p'+item]['no_win_odds'] !== '-'">{{convert_coef(main_bets['p'+item]['no_win_odds'], race7_bets_obj.coef_type)}}</span>
          </button>
        </div>
        <div v-for="item in 7" :key="item" :class="'sector_show_'+item" class="bet-cell">
          <button class="roulette-btn-base" :class="checkIsSelected(checkWinnerBets('show_')+item)" :disabled="race7_bets_obj.main_enabled === 'f' || (bet_switch === true && main_bets['p'+item]['show_odds'] === '-') || (bet_switch !== true && main_bets['p'+item]['no_show_odds'] === '-')" @click="getOneBet(checkWinnerBets('show_')+item, main_bets['p'+item][checkWinnerBetsObj('show_odds')])">
            <i v-if="race7_bets_obj.main_enabled === 'f' || (bet_switch === true && main_bets['p'+item]['show_odds'] === '-') || (bet_switch !== true && main_bets['p'+item]['no_show_odds'] === '-')" class="fa fa-star-o txt-color-blueLight"><font-awesome-icon :icon="['fas', 'lock']" /></i>
            <span v-if="race7_bets_obj.main_enabled === 't' && bet_switch === true && main_bets['p'+item]['show_odds'] !== '-'">{{convert_coef(main_bets['p'+item]['show_odds'], race7_bets_obj.coef_type)}}</span>
            <span v-if="race7_bets_obj.main_enabled === 't' && bet_switch !== true && main_bets['p'+item]['no_show_odds'] !== '-'">{{convert_coef(main_bets['p'+item]['no_show_odds'], race7_bets_obj.coef_type)}}</span>
          </button>
        </div>
        <div v-for="item in 7" :key="item" :class="'sector_place_'+item" class="bet-cell">
          <button class="roulette-btn-base" :class="checkIsSelected(checkWinnerBets('place_')+item)" :disabled="race7_bets_obj.main_enabled === 'f' || (bet_switch === true && main_bets['p'+item]['place_odds'] === '-') || (bet_switch !== true && main_bets['p'+item]['no_place_odds'] === '-')" @click="getOneBet(checkWinnerBets('place_')+item, main_bets['p'+item][checkWinnerBetsObj('place_odds')])">
            <i v-if="race7_bets_obj.main_enabled === 'f' || (bet_switch === true && main_bets['p'+item]['place_odds'] === '-') || (bet_switch !== true && main_bets['p'+item]['no_place_odds'] === '-')" class="fa fa-star-o txt-color-blueLight"><font-awesome-icon :icon="['fas', 'lock']" /></i>
            <span v-if="race7_bets_obj.main_enabled === 't' && bet_switch === true && main_bets['p'+item]['place_odds'] !== '-'">{{convert_coef(main_bets['p'+item]['place_odds'], race7_bets_obj.coef_type)}}</span>
            <span v-if="race7_bets_obj.main_enabled === 't' && bet_switch !== true && main_bets['p'+item]['no_place_odds'] !== '-'">{{convert_coef(main_bets['p'+item]['no_place_odds'], race7_bets_obj.coef_type)}}</span>
          </button>
        </div>
        <div v-for="item in 7" :key="item" class="bet-cell dogs_table_label_cell" :class="'sector_dogicon_'+item">
          <div class="icon_star">
            <i class="fa fa-star-o"><font-awesome-icon :icon="['fas', 'star']" /></i>
            <i class="fa fa-star-o">
              <font-awesome-icon v-if="race7_bets_obj.weights['p'+item]*1 > 0.2" :icon="['fas', 'star']" />
              <font-awesome-icon v-if="race7_bets_obj.weights['p'+item]*1 <= 0.2" :icon="['far', 'star']" />
            </i>
            <i class="fa fa-star-o">
              <font-awesome-icon v-if="race7_bets_obj.weights['p'+item]*1 > 0.4" :icon="['fas', 'star']" />
              <font-awesome-icon v-if="race7_bets_obj.weights['p'+item]*1 <= 0.4" :icon="['far', 'star']" />
            </i>
            <i class="fa fa-star-o">
              <font-awesome-icon v-if="race7_bets_obj.weights['p'+item]*1 > 0.6" :icon="['fas', 'star']" />
              <font-awesome-icon v-if="race7_bets_obj.weights['p'+item]*1 <= 0.6" :icon="['far', 'star']" />
            </i>
            <i class="fa fa-star-o">
              <font-awesome-icon v-if="race7_bets_obj.weights['p'+item]*1 > 0.8" :icon="['fas', 'star']" />
              <font-awesome-icon v-if="race7_bets_obj.weights['p'+item]*1 <= 0.8" :icon="['far', 'star']" />
            </i>
          </div>
          <div class="sector_dogicon bet-cell" :class="'sector_dogicon_icon_'+item"></div>
        </div>
      </div>
      <div class="dogs6-grid-lg-extra">
        <div class="sector_odd bet-cell">
          <button class="roulette-btn-base dogs_table_label_cell" :class="checkIsSelected('race_odd')" style="width: 100%; height: 100%; padding: 0; line-height: inherit;" :disabled="race7_bets_obj.extra_enabled === 'f'" @click="getOneBet('race_odd', extra_bets.Odd)">
            <div class="btn-extra-black btn-black" style="justify-content: center;overflow: hidden;">
              <div style="width: 55%;">{{$t('cashier.race.odd_label')}}</div>
              <div style="display: flex;flex-direction: row;">
                <div class="sector_dogicon sector_dogicon_icon_1 visible-lg" style=" height: 100%; width: 30px;"></div>
                <div class="sector_dogicon sector_dogicon_icon_3 visible-lg" style=" height: 100%; width: 30px;"></div>
                <div class="sector_dogicon sector_dogicon_icon_5 visible-lg" style=" height: 100%; width: 30px;"></div>
                <div class="sector_dogicon sector_dogicon_icon_7 visible-lg" style=" height: 100%; width: 30px;"></div>
              </div>
            </div>
            <div>
              <i v-if="race7_bets_obj.extra_enabled === 'f'" class="fa fa-star-o txt-color-blueLight"><font-awesome-icon :icon="['fas', 'lock']" /></i>
              <span v-if="race7_bets_obj.extra_enabled === 't'">{{convert_coef(extra_bets.Odd, race7_bets_obj.coef_type)}}</span>
            </div>
          </button>
        </div>
        <div class="sector_even bet-cell">
          <button class="roulette-btn-base dogs_table_label_cell" :class="checkIsSelected('race_even')" style="width: 100%; height: 100%; padding: 0; line-height: inherit;" :disabled="race7_bets_obj.extra_enabled === 'f'" @click="getOneBet('race_even', extra_bets.Even)">
            <div class="btn-extra-black btn-black" style="justify-content: center;overflow: hidden;">
              <div style="width: 55%;">{{$t('cashier.race.even_label')}}</div>
              <div style="display: flex;flex-direction: row;">
                <div class="sector_dogicon sector_dogicon_icon_2 visible-lg" style=" height: 100%; width: 30px;"></div>
                <div class="sector_dogicon sector_dogicon_icon_4 visible-lg" style=" height: 100%; width: 30px;"></div>
                <div class="sector_dogicon sector_dogicon_icon_6 visible-lg" style=" height: 100%; width: 30px;"></div>
              </div>
            </div>
            <div>
              <i v-if="race7_bets_obj.extra_enabled === 'f'" class="fa fa-star-o txt-color-blueLight"><font-awesome-icon :icon="['fas', 'lock']" /></i>
              <span v-if="race7_bets_obj.extra_enabled === 't'">{{convert_coef(extra_bets.Even, race7_bets_obj.coef_type)}}</span>
            </div>
          </button>
        </div>
        <div class="sector_low bet-cell">
          <button class="roulette-btn-base dogs_table_label_cell" :class="checkIsSelected('lower')" style="width: 100%; height: 100%; padding: 0; line-height: inherit;" :disabled="race7_bets_obj.extra_enabled === 'f'" @click="getOneBet('lower', extra_bets.Lower)">
            <div class="btn-extra-black btn-black" style="justify-content: center;overflow: hidden;">
              <div style="width: 55%;">{{$t('cashier.race.lower_label')}}</div>
              <div style="display: flex;flex-direction: row;">
                <div class="sector_dogicon sector_dogicon_icon_1 visible-lg" style=" height: 100%; width: 30px;"></div>
                <div class="sector_dogicon sector_dogicon_icon_2 visible-lg" style=" height: 100%; width: 30px;"></div>
                <div class="sector_dogicon sector_dogicon_icon_3 visible-lg" style=" height: 100%; width: 30px;"></div>
              </div>
            </div>
            <div>
              <i v-if="race7_bets_obj.extra_enabled === 'f'" class="fa fa-star-o txt-color-blueLight"><font-awesome-icon :icon="['fas', 'lock']" /></i>
              <span v-if="race7_bets_obj.extra_enabled === 't'">{{convert_coef(extra_bets.Lower, race7_bets_obj.coef_type)}}</span>
            </div>
          </button>
        </div>
        <div class="sector_high bet-cell">
          <button class="roulette-btn-base dogs_table_label_cell" :class="checkIsSelected('higher')" style="width: 100%; height: 100%; padding: 0; line-height: inherit;"  :disabled="race7_bets_obj.extra_enabled === 'f'" @click="getOneBet('higher', extra_bets.Higher)">
            <div class="btn-extra-black btn-black" style="justify-content: center;overflow: hidden;">
              <div style="width: 55%;">{{$t('cashier.race.high_label')}}</div>
              <div style="display: flex;flex-direction: row;">
                <div class="sector_dogicon sector_dogicon_icon_4 visible-lg" style=" height: 100%; width: 30px;"></div>
                <div class="sector_dogicon sector_dogicon_icon_5 visible-lg" style=" height: 100%; width: 30px;"></div>
                <div class="sector_dogicon sector_dogicon_icon_6 visible-lg" style=" height: 100%; width: 30px;"></div>
                <div class="sector_dogicon sector_dogicon_icon_7 visible-lg" style=" height: 100%; width: 30px;"></div>
              </div>
            </div>
            <div>
              <i v-if="race7_bets_obj.extra_enabled === 'f'" class="fa fa-star-o txt-color-blueLight"><font-awesome-icon :icon="['fas', 'lock']" /></i>
              <span v-if="race7_bets_obj.extra_enabled === 't'">{{convert_coef(extra_bets.Higher, race7_bets_obj.coef_type)}}</span>
            </div>
          </button>
        </div>
      </div>
    </div>
    <div v-if="bet_type === 'exacta'" class="dogs-section">
      <div class="dogs6-grid-lg-exacta">
        <button v-for="item in exacta_template" :key="item" class="roulette-btn-base dogs_table_exacta_cell" :class="checkIsSelected('exacta_'+item.p1+'_'+item.p2)" style="display: flex; width: 100%; height: 100%; padding: 0; line-height: inherit; " :disabled="race7_bets_obj.exacta_enabled === 'f'" @click="getOneBet('exacta_'+item.p1+'_'+item.p2, exacta_bets['p'+item.p1]['p'+item.p2])">
          <div class="dogs_table_exacta_lable_black btn-black" style="width: 35%;">{{item.p1+'-'+item.p2}}</div>
          <div v-if="race7_bets_obj.exacta_enabled !== 'f'" style="width: 65%;">{{convert_coef(exacta_bets['p'+item.p1]['p'+item.p2], race7_bets_obj.coef_type)}}</div>
          <div v-if="race7_bets_obj.exacta_enabled === 'f'"  style="width: 65%;"><i class="fa fa-star-o txt-color-blueLight"><font-awesome-icon :icon="['fas', 'lock']" /></i></div>
        </button>
      </div>
    </div>
    <div v-if="bet_type === 'quinella'" class="dogs-section">
      <div class="dogs6-grid-lg-quinella">
        <button v-for="item in exacta_template" :key="item" :disabled="!item.q_bet || race7_bets_obj.exacta_enabled === 'f'" :class="{'roulette-btn-base':  item.q_bet, 'btn-black quinella-child': item.q_bet,  'dogs_table_quinella_cell': item.q_bet, 'dnone': !item.q_bet}" style="display: flex; width: 100%; height: 100%; padding: 0; line-height: inherit; " @click="getQuinBet(item.p1,item.p2)">
          <span v-if="item.q_bet">{{item.p1}} <i class="fa fa-exchange" style="padding-left: 5px; padding-right: 5px; font-size: 16px;"><font-awesome-icon :icon="['fas', 'exchange-alt']" /></i> {{item.p2}}</span>
          <span v-if="!item.q_bet"></span>
        </button>
      </div>
    </div>
    <div v-if="bet_type === 'trifecta'" class="dogs-section">
      <div class="dogs6-grid-lg-trifecta">
        <div class="trifectaTab-selectBlock">
          <div class="trifectaTab-tableManager">
            <div class="trifectaTab-upBtn" style="height: 45px;">
              <button class="roulette-btn-base" style="display: flex;align-items: center;justify-content: center;" @click="decTb()"><i class="fa fa-caret-up" style="padding-left: 5px; padding-right: 5px; font-size: 16px;"><font-awesome-icon :icon="['fas', 'caret-up']" /></i></button>
            </div>
            <div class="trifectaTab-tableNum">
              <div class="trif-btn-bg" :class="'trif-slide-'+trifecta_tb"></div>
            </div>
            <div class="trifectaTab-downBtn" style="height: 45px;">
              <button class="roulette-btn-base" style="display: flex;align-items: center;justify-content: center;" @click="incTb()"><i class="fa fa-caret-down" style="padding-left: 5px; padding-right: 5px; font-size: 16px;"><font-awesome-icon :icon="['fas', 'caret-down']" /></i></button>
            </div>
          </div>
        </div>
        <div class="trifectaTab-contentBlock">
          <div class="trifectaTab-contentBlock-scroll" :style="'transform: translateY('+(trifecta_tb-1)*-287+'px);'">
            <div v-for="tb in 8" :key="tb" class="trifectaTab-table" >
              <button v-for="bt in tb_template['p'+tb]" :key="bt" class="roulette-btn-base dogs_table_exacta_cell" :class="checkIsSelected('trifecta_'+trifecta_tb+'_'+bt.p2+'_'+bt.p3)" style="display: flex; width: 100%; height: 32px; padding: 0; line-height: inherit; " :disabled="race7_bets_obj.trifecta_enabled === 'f'" @click="getOneBet('trifecta_'+trifecta_tb+'_'+bt.p2+'_'+bt.p3, trifecta_bets['p'+bt.p2]['p'+bt.p3])">
                <div class="dogs_table_exacta_lable_black btn-black" style="width: 35%; justify-content: center;">{{tb+'-'+bt.p2+'-'+bt.p3}}</div>
                <div v-if="trifecta_bets['p'+bt.p2] !== undefined && trifecta_bets['p'+bt.p2]['p'+bt.p3] !== undefined && race7_bets_obj.trifecta_enabled === 't'" style="width: 65%;">{{convert_coef(trifecta_bets['p'+bt.p2]['p'+bt.p3], race7_bets_obj.coef_type)}}</div>
                <div v-if="race7_bets_obj.trifecta_enabled === 'f'"  style="width: 65%;"><i class="fa fa-star-o txt-color-blueLight"><font-awesome-icon :icon="['fas', 'lock']" /></i></div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="dogs6-grid-lg-menu">
      <div class="menu_winner menu-dogs" :class="{'has-triangle': bet_type === 'winner'}" @click="setBetType('winner')">
        <button class="roulette-btn-base" :class="{'btn-black': bet_type === 'winner'}">{{$t('cashier.race.sw_win')}}</button>
      </div>
      <div class="menu_exacta menu-dogs" :class="{'has-triangle': bet_type === 'exacta'}" @click="setBetType('exacta')">
        <button class="roulette-btn-base" :class="{'btn-black': bet_type === 'exacta'}">{{$t('cashier.race.sw_exacta')}}</button>
      </div>
      <div class="menu_quinella menu-dogs" :class="{'has-triangle': bet_type === 'quinella'}" @click="setBetType('quinella')">
        <button class="roulette-btn-base" :class="{'btn-black': bet_type === 'quinella'}">{{$t('cashier.race.sw_quinella')}}</button>
      </div>
      <div class="menu_trifecta menu-dogs" :class="{'has-triangle': bet_type === 'trifecta'}" @click="setBetType('trifecta')">
        <button class="roulette-btn-base" :class="{'btn-black': bet_type === 'trifecta'}">{{$t('cashier.race.sw_trifecta')}}</button>
      </div>
      <!--div class="menu_top menu-dogs" :class="{'has-triangle': bet_type === 'top'}" @click="setBetType('top')">
        <button class="roulette-btn-base" :class="{'btn-black': bet_type === 'top'}">{{$t('cashier.race.sw_top')}}</button>
      </div-->
    </div>
  </div>
</template>

<script>
import {inject, registerRuntimeCompiler} from "vue";
import Toggle from '@vueform/toggle'

export default {
  name: "Race7",
  data () {
    return {
      bet_type: 'winner',
      bet_switch: true,
      main_bets: [],
      exacta_bets: [],
      extra_bets: [],
      selected: [],
      cur_split_selected: [],
      pickCount: 0,
      trifecta_tb: 1,
      exacta_template:
      [
        {"p1": "1", "p2": "2", "q_bet": true},
        {"p1": "1", "p2": "3", "q_bet": true},
        {"p1": "1", "p2": "4", "q_bet": true},
        {"p1": "1", "p2": "5", "q_bet": true},
        {"p1": "1", "p2": "6", "q_bet": true},
        {"p1": "1", "p2": "7", "q_bet": true},
        {"p1": "2", "p2": "1", "q_bet": false},
        {"p1": "2", "p2": "3", "q_bet": true},
        {"p1": "2", "p2": "4", "q_bet": true},
        {"p1": "2", "p2": "5", "q_bet": true},
        {"p1": "2", "p2": "6", "q_bet": true},
        {"p1": "2", "p2": "7", "q_bet": true},
        {"p1": "3", "p2": "1", "q_bet": false},
        {"p1": "3", "p2": "2", "q_bet": false},
        {"p1": "3", "p2": "4", "q_bet": true},
        {"p1": "3", "p2": "5", "q_bet": true},
        {"p1": "3", "p2": "6", "q_bet": true},
        {"p1": "3", "p2": "7", "q_bet": true},
        {"p1": "4", "p2": "1", "q_bet": false},
        {"p1": "4", "p2": "2", "q_bet": false},
        {"p1": "4", "p2": "3", "q_bet": false},
        {"p1": "4", "p2": "5", "q_bet": true},
        {"p1": "4", "p2": "6", "q_bet": true},
        {"p1": "4", "p2": "7", "q_bet": true},
        {"p1": "5", "p2": "1", "q_bet": false},
        {"p1": "5", "p2": "2", "q_bet": false},
        {"p1": "5", "p2": "3", "q_bet": false},
        {"p1": "5", "p2": "4", "q_bet": false},
        {"p1": "5", "p2": "6", "q_bet": true},
        {"p1": "5", "p2": "7", "q_bet": true},
        {"p1": "6", "p2": "1", "q_bet": false},
        {"p1": "6", "p2": "2", "q_bet": false},
        {"p1": "6", "p2": "3", "q_bet": false},
        {"p1": "6", "p2": "4", "q_bet": false},
        {"p1": "6", "p2": "5", "q_bet": false},
        {"p1": "6", "p2": "7", "q_bet": true},
        {"p1": "7", "p2": "1", "q_bet": false},
        {"p1": "7", "p2": "2", "q_bet": false},
        {"p1": "7", "p2": "3", "q_bet": false},
        {"p1": "7", "p2": "4", "q_bet": false},
        {"p1": "7", "p2": "5", "q_bet": false},
        {"p1": "7", "p2": "6", "q_bet": false}
      ],
      tb_template: []
    }
  },
  setup()
  {
    const game_kind = inject('gameKind')
    const game_alias = inject('gameAlias')
    const extra_bet = inject('gameExtra')
    const race7_bets_obj = inject('race7_bets_obj')
    const convert_coef = inject('convert_coef');

    return {
      game_kind,
      game_alias,
      extra_bet,
      race7_bets_obj,
      convert_coef
    }
  },
  props: ['event', 'public_event', 'bet_value'],
  emits: ['splitBets', 'getBet'],
  components: {
    Toggle
  },
  methods: {
    getBet(bet)
    {
      if(this.event === '')
      {
        return;
      }

      let iter = 0;

      if(this.pickCount > 0)
      {
        this.cur_split_selected = [];
        iter = this.pickCount-1;
      }

      let even = this.event;

      let found = '';

      for (let sel of this.cur_split_selected)
      {
        if (sel.bet === bet)
        {
          found = sel;
        }
      }

      if (found !== '')
      {
        this.cur_split_selected.splice(this.cur_split_selected.indexOf(found), 1);
      }
      else
      {
        if(this.cur_split_selected.length < 10)
        {
          let over = 0;

          for(let i = 0; i <= iter; i++)
          {
            let bb = bet;

            if(this.pickMode === 'h')
            {
              bb = bb*1+i;

              if(over > 0)
              {
                bb = Math.floor(bb/10)*10-10+over;
              }

              if(Math.floor(bb/10) === bb/10 || over > 0)
              {
                over++;
              }
            }

            if(this.pickMode === 'v')
            {
              bb = bb*1+i*10;

              if(bb > 80 || over > 0)
              {
                over++;
              }
              if(over > 0)
              {
                bb = bb-80;
              }
            }

            bb = bb.toString();
            this.cur_split_selected.push({bet: bb, event: even});
          }
        }
      }
    },
    addBet()
    {
      if(this.cur_split_selected.length === 0)
      {
        return;
      }

      let bet = 'keno'+this.cur_split_selected.length;
      let bet_name = '';

      for(let sel of this.cur_split_selected)
      {
        if(bet_name !== '')
        {
          bet_name = bet_name + ';';
        }
        bet_name = bet_name + sel.bet;
        bet = bet + '_' + sel.bet;
      }

      this.selected.push({
        bet: bet,
        event: this.event,
        public_event: this.public_event,
        bet_value: this.bet_value,
        multiplier: 1,
        bet_name: bet_name,
        ntt: 'n',
        coef_type: this.race7_bets_obj.coef_type
      });

      this.$emit('getBet', this.selected);

      this.cur_split_selected = [];
    },
    getOneBet(bet, multi)
    {
      let bet_name = bet;
      let even = this.event;
      let found = '';

      for (let sel of this.selected)
      {
        if (sel.bet === bet)
        {
          found = sel;
        }
      }

      if (found !== '')
      {
        this.selected.splice(this.selected.indexOf(found), 1);
      }
      else
      {
        this.selected.push({
          bet: bet,
          event: this.event,
          public_event: this.public_event,
          bet_value: this.bet_value,
          multiplier: multi,
          bet_name: bet_name,
          ntt: 'n',
          coef_type: this.race7_bets_obj.coef_type
        });
      }

      this.$emit('getBet', this.selected);
    },
    getQuinBet(f, s)
    {
      let m;
      console.log(this.exacta_bets)
      m = this.exacta_bets['p'+f]['p'+s];
      console.log(m);
      this.getOneBet('exacta_'+f+'_'+s, m);
      m = this.exacta_bets['p'+s]['p'+f];
      this.getOneBet('exacta_'+s+'_'+f, m);
    },
    checkIsSelected(val)
    {
      let event = this.event;
      let ret = '';

      for(let sel of this.selected)
      {
        if(sel.event === event)
        {
          if(sel.bet === val)
          {
            ret += 'selected ';
          }
        }
      }

      return ret;
    },
    setMode(mode, cnt)
    {
      if(mode !== 'normal' && cnt !== 0)
      {
        this.cur_split_selected = [];
      }

      if(mode === this.pickMode && cnt === this.pickCount)
      {
        this.pickMode = 'normal';
        this.pickCount = 0;
      }
      else
      {
        this.pickMode = mode;
        this.pickCount = cnt;
      }
    },
    clear_bet()
    {
      this.selected = [];
    },
    addBetEvent(event)
    {
      if (event.keyCode == 32)
      {
        event.preventDefault();
        this.addBet();
      }
    },
    setBetType(type)
    {
      this.bet_type = type;
    },
    setTrifectaTb(tb)
    {
      this.trifecta_tb = tb;
    },
    cutKeyP(key)
    {
      return key.replace('p', '')
    },
    checkWinnerBets(bet)
    {
      if(this.bet_switch === false)
      {
        return 'no-'+bet;
      }

      return bet;
    },
    checkWinnerBetsObj(bet)
    {
      if(this.bet_switch === false)
      {
        return 'no_'+bet;
      }

      return bet;
    },
    decTb()
    {
      if (this.trifecta_tb === 1)
      {
        this.trifecta_tb = 7
      }
      else
      {
        this.trifecta_tb--;
      }
    },
    incTb()
    {
      if (this.trifecta_tb === 7)
      {
        this.trifecta_tb = 1
      }
      else
      {
        this.trifecta_tb++;
      }
    }
  },
  watch:
  {
    race7_bets_obj(newVal,oldVal) {
      this.main_bets = this.race7_bets_obj.main_bets
      console.log(this.race7_bets_obj)
      this.exacta_bets = this.race7_bets_obj.exacta_bets
      this.extra_bets = this.race7_bets_obj.extra_bets

      for (let i=1; i <= 7; i++)
      {
        let cur_tfb_obj = this.race7_bets_obj['trifecta_bets_'+i];
        let s_arr = []

        for (let f in cur_tfb_obj)
        {
          for (let s in cur_tfb_obj[f])
          {
            if (cur_tfb_obj[f][s] !== '-')
            {
              s_arr.push({p2: this.cutKeyP(f), p3: this.cutKeyP(s)})
            }
          }
        }

        this.tb_template['p'+i] = s_arr
      }
    }
  },
  computed:
  {
    trifecta_bets() {
      let obj_name = 'p'+this.trifecta_tb+1;
      return this.race7_bets_obj['trifecta_bets_'+this.trifecta_tb];
    }
  }

}
</script>

<style scoped>
  .dogs6-grid-lg
  {

    display: grid;
    grid-gap: 4px;
    grid-row-gap: 8px;
    grid-template-areas:
        'sector_none sector_none sector_dogicon_1 sector_dogicon_2 sector_dogicon_3 sector_dogicon_4 sector_dogicon_5 sector_dogicon_6 sector_dogicon_7 sector_dogicon_8'
        'sector_win_label sector_win_label sector_win_1 sector_win_2 sector_win_3 sector_win_4 sector_win_5 sector_win_6 sector_win_7 sector_win_8'
        'sector_place_label sector_place_label sector_place_1 sector_place_2 sector_place_3 sector_place_4 sector_place_5 sector_place_6 sector_place_7 sector_place_8'
        'sector_show_label sector_show_label sector_show_1 sector_show_2 sector_show_3 sector_show_4 sector_show_5 sector_show_6 sector_show_7 sector_show_8'
        'sector_odd sector_odd sector_even sector_even sector_low sector_low sector_high sector_high sector_high sector_high';
  }
  .sector_none {
    grid-area: sector_none;
  }
  .sector_none
  {
    --toggle-bg-on: #3276b1;
    --toggle-border-on: #3276b1;
    --toggle-border-on-disabled: #d1d5db;
  }
  .toggle-container:focus
  {
    box-shadow: none !important;
  }
  .bet-cell {
    display: flex;
    /* cursor: pointer; */
    align-self: justify;
    justify-self: justify;
    line-height: inherit;
    justify-content: center;
    align-items: center;
    min-width: 50px;
    min-height: 42px;
  }
  .roulette-btn-base {
    width: 100%;
    height: 100%;
    border-radius: 4px;
    display: inline-block;
    padding: 4px 2px;
    margin-bottom: 0;
    color: #333;
    text-align: center;
    text-shadow: 0 1px 1px hsl(0deg 0% 100% / 75%);
    vertical-align: middle;
    cursor: pointer;
    background-color: #f5f5f5;
    background-image: linear-gradient(180deg, #fff, #e6e6e6);
    background-repeat: repeat-x;
    border: 1px solid rgba(0, 0, 0, .15);
    border-bottom-color: #a2a2a2;
    border-radius: 4px;
    box-shadow: inset 0 1px 0 hsl(0deg 0% 100% / 20%), 0 1px 2px rgb(0 0 0 / 5%);
    font-weight: 700;
    font-size: 19.6px;
    line-height: 13px;
  }
  .dogs_cell_gradient {
    background: linear-gradient(to right, #92a2a878, transparent);
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  .sector_win_label {
    grid-area: sector_win_label;
  }
  .sector_place_label {
    grid-area: sector_place_label;
  }
  .sector_show_label {
    grid-area: sector_show_label;
  }
  .dogs_table_label_cell {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .icon_star {
    display: flex;
    flex-direction: row;
    color: #555;
  }
  .dogs_table_label_text {
    color: black;
  }
  .txt-color-blueDark {
    color: #4c4f53!important;
  }
  .fa {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  i {
    font-style: italic;
  }
  .sector_win_1 {
    grid-area: sector_win_1;
  }
  .sector_place_1 {
    grid-area: sector_place_1;
  }
  .sector_show_1 {
    grid-area: sector_show_1;
  }
  .sector_win_2 {
    grid-area: sector_win_2;
  }
  .sector_place_2 {
    grid-area: sector_place_2;
  }
  .sector_show_2 {
    grid-area: sector_show_2;
  }
  .sector_win_3 {
    grid-area: sector_win_3;
  }
  .sector_place_3 {
    grid-area: sector_place_3;
  }
  .sector_show_3 {
    grid-area: sector_show_3;
  }
  .sector_win_4 {
    grid-area: sector_win_4;
  }
  .sector_place_4 {
    grid-area: sector_place_4;
  }
  .sector_show_4 {
    grid-area: sector_show_4;
  }
  .sector_win_5 {
    grid-area: sector_win_5;
  }
  .sector_place_5 {
    grid-area: sector_place_5;
  }
  .sector_show_5 {
    grid-area: sector_show_5;
  }
  .sector_win_6 {
    grid-area: sector_win_6;
  }
  .sector_place_6 {
    grid-area: sector_place_6;
  }
  .sector_show_6 {
    grid-area: sector_show_6;
  }
  .sector_win_7 {
    grid-area: sector_win_7;
  }
  .sector_place_7 {
    grid-area: sector_place_7;
  }
  .sector_show_7 {
    grid-area: sector_show_7;
  }
  .sector_win_8 {
    grid-area: sector_win_8;
  }
  .sector_place_8 {
    grid-area: sector_place_8;
  }
  .sector_show_8 {
    grid-area: sector_show_8;
  }
  .sector_dogicon_1 {
    grid-area: sector_dogicon_1;
  }
  .sector_dogicon_2 {
    grid-area: sector_dogicon_2;
  }
  .sector_dogicon_3 {
    grid-area: sector_dogicon_3;
  }
  .sector_dogicon_4 {
    grid-area: sector_dogicon_4;
  }
  .sector_dogicon_5 {
    grid-area: sector_dogicon_5;
  }
  .sector_dogicon_6 {
    grid-area: sector_dogicon_6;
  }
  .sector_dogicon_7 {
    grid-area: sector_dogicon_7;
  }
  .sector_dogicon_8 {
    grid-area: sector_dogicon_8;
  }
  .sector_dogicon {
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }
  .sector_dogicon_icon_1
  {
    background-image: url(../../../public/RacersNumbers/1.png);
  }
  .sector_dogicon_icon_2
  {
    background-image: url(../../../public/RacersNumbers/2.png);
  }
  .sector_dogicon_icon_3
  {
    background-image: url(../../../public/RacersNumbers/3.png);
  }
  .sector_dogicon_icon_4
  {
    background-image: url(../../../public/RacersNumbers/4.png);
  }
  .sector_dogicon_icon_5
  {
    background-image: url(../../../public/RacersNumbers/5.png);
  }
  .sector_dogicon_icon_6
  {
    background-image: url(../../../public/RacersNumbers/6.png);
  }
  .sector_dogicon_icon_7
  {
    background-image: url(../../../public/RacersNumbers/7.png);
  }
  .sector_dogicon_icon_8
  {
    background-image: url(../../../public/RacersNumbers/8.png);
  }
  .dogs6-grid {
    background-color: transparent;
    display: grid;
    color: white;
    justify-items: stretch;
    align-items: stretch;
    text-align: center;
    vertical-align: middle;
    width: 98%;
  }
  .dogs6-grid-lg-extra {
    display: grid;
    grid-gap: 8px;
    grid-template-areas:
        'sector_odd sector_even sector_low sector_high';
    margin-top: 20px;
    grid-template-columns: auto;
  }
  .sector_odd {
    grid-area: sector_odd;
    width: 100%;
    height: 100%;
    /* margin-top: 20px; */
  }
  .sector_even {
    grid-area: sector_even;
    width: 100%;
    height: 100%;
    /* margin-top: 20px; */
  }
  .sector_low {
    grid-area: sector_low;
    width: 100%;
    height: 100%;
    /* margin-top: 20px; */
  }
  .sector_high {
    grid-area: sector_high;
    width: 100%;
    height: 100%;
    /* margin-top: 20px; */
  }
  .btn-extra-black {
    width: 100%;
    height: 100%;
    padding-right: 10px;
    padding-left: 10px;
    margin: 0;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    display: flex;
    flex-direction: row;
  }
  .btn-black {
    color: #fff;
    text-shadow: 0 -1px 0 rgb(0 0 0 / 25%);
    background-color: #363636;
    background-image: linear-gradient(180deg, #444, #222);
    background-repeat: repeat-x;
    border-color: #222 #222 #000;
    border-color: rgba(0, 0, 0, .1) rgba(0, 0, 0, .1) rgba(0, 0, 0, .25);
  }
  .visible-lg {
    display: block!important;
  }
  .black_text
  {
    color: black;
  }
  .dogs6-grid-lg-menu {
    display: grid;
    grid-gap: 4px;
    grid-template-areas: 'menu_winner menu_place menu_quinella menu_show menu_top';
    margin-top: 20px;
    min-height: 60px;
  }
  .menu-dogs {
    position: relative;
    display: inline-block;
  }
  .menu_winner {
    grid-area: menu_winner;
    width: 100%;
    height: 100%;
  }
  .menu-dogs.has-triangle::before {
    content: '';
    position: absolute;
    top: -17%;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #363636;
  }
  .dogs6-grid-lg-exacta {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-template-rows: repeat(8, 1fr);
    grid-gap: 4px;
    height: 100%;
  }
  .dogs_table_exacta_lable_black {
    width: 100%;
    height: 100%;
    padding-right: 10px;
    padding-left: 10px;
    margin: 0;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    display: flex;
    align-items: center;
    overflow: hidden;
    /* flex-direction: row; */
  }
  .dogs_table_exacta_cell {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .txt-color-blueLight {
    color: #92a2a8!important;
  }
  .dogs6-grid-lg-quinella {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-template-rows: repeat(7, 1fr);
    justify-items: end;
    grid-gap: 4px;
    height: 100%;
  }
  .dogs6-grid-lg-quinella .quinella-child:nth-child(1) {
    grid-column: 1;
    grid-row: 1;
  }
  .dogs_table_quinella_cell {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 40px;
  }
  .quinella-child {
    height: 40px;
  }
  .dnone
  {
    border: none;
    background-color: white;
    cursor: auto;
  }
  .dogs6-grid-lg-trifecta {
    display: grid;
    grid-template-columns: 60px minmax(0, 1fr);
    grid-template-rows: 1fr;
    grid-gap: 4px;
    height: 100%;
  }
  .trifectaTab-selectBlock {
    width: 100%;
    /* height: calc(750px*var(--zoomCoef)); */
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
    text-shadow: 0 1px 1px hsl(0deg 0% 100% / 75%);
    font-weight: 700;
    /* text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000; */
  }
  .trifectaTab-tableManager {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  .trifectaTab-upBtn, .trifectaTab-downBtn {
    align-self: stretch;
  }
  .trifectaTab-tableNum {
    flex-grow: 1;
    padding-top: 5px;
    padding-bottom: 5px;
    width: 100%;
  }
  .trif-slide-1 {
    background: linear-gradient(#660006, #E67F88, #660006);
  }
  .trif-slide-1::before {
    content: "1";
    margin: auto;
    color: white;
    /* text-shadow: -1px -1px 0 white, 1px -1px 0 white, -1px 1px 0 white, 1px 1px 0 white; */
  }
  .trif-slide-2 {
    background: linear-gradient(#033D7A, #79AFE0, #033D7A);
  }
  .trif-slide-2::before {
    content: "2";
    margin: auto;
    color: white;
    /* text-shadow: -1px -1px 0 white, 1px -1px 0 white, -1px 1px 0 white, 1px 1px 0 white; */
  }
  .trif-slide-3 {
    background: linear-gradient(#7A7979, #FFFFFF, #7A7979);
  }
  .trif-slide-3::before {
    content: "3";
    margin: auto;
    color: #030304;
    /* text-shadow: -1px -1px 0 #030304, 1px -1px 0 #030304, -1px 1px 0 #030304, 1px 1px 0 #030304; */
  }
  .trif-slide-4 {
    background: linear-gradient(#030202, #979796, #030202);
  }
  .trif-slide-4::before {
    content: "4";
    margin: auto;
    color: white;
    /* text-shadow: -1px -1px 0 white, 1px -1px 0 white, -1px 1px 0 white, 1px 1px 0 white; */
  }
  .trif-slide-5 {
    background: linear-gradient(#A75102, #FFBA77, #A75102);
  }
  .trif-slide-5::before {
    content: "5";
    margin: auto;
    color: #060606;
    /* text-shadow: -1px -1px 0 #060606, 1px -1px 0 #060606, -1px 1px 0 #060606, 1px 1px 0 #060606; */
  }
  .trif-btn-bg {
    height: 100%;
    border-radius: 4px;
    display: flex;
    font-size: 80px;
    border: 1px solid rgba(0, 0, 0, .15);
    /* line-height: normal; */
    /* margin: auto; */
  }
  .trif-slide-6 {
    /* background: linear-gradient(#010001, #F5F6F6, #010001, #F5F6F6, #010001, #F5F6F6, #010001); */
    /* background: linear-gradient(to bottom, #010001 0%, #010001 14.28%, #F5F6F6 14.28%, #F5F6F6 28.56%, #010001 28.56%, #010001 42.84%, #F5F6F6 42.84%, #F5F6F6 57.12%, #010001 57.12%, #010001 71.4%, #F5F6F6 71.4%, #F5F6F6 85.68%, #010001 85.68%, #010001 100%); */
    background: linear-gradient(to bottom, #010001 0%, #010001 11.11%, #F5F6F6 11.11%, #F5F6F6 22.22%, #010001 22.22%, #010001 33.33%, #F5F6F6 33.33%, #F5F6F6 44.44%, #010001 44.44%, #010001 55.56%, #F5F6F6 55.56%, #F5F6F6 66.67%, #010001 66.67%, #010001 77.78%, #F5F6F6 77.78%, #F5F6F6 88.89%, #010001 88.89%, #010001 100%);
  }
  .trif-slide-6::before {
    content: "6";
    margin: auto;
    color: #E90008;
    text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff;
  }
  .trif-slide-7 {
    /* background: linear-gradient(#010001, #F5F6F6, #010001, #F5F6F6, #010001, #F5F6F6, #010001); */
    /* background: linear-gradient(to bottom, #010001 0%, #010001 14.28%, #F5F6F6 14.28%, #F5F6F6 28.56%, #010001 28.56%, #010001 42.84%, #F5F6F6 42.84%, #F5F6F6 57.12%, #010001 57.12%, #010001 71.4%, #F5F6F6 71.4%, #F5F6F6 85.68%, #010001 85.68%, #010001 100%); */
    background: linear-gradient(#5EB86B, #30783A, #348B40);
  }
  .trif-slide-7::before {
    content: "7";
    margin: auto;
    color: #E90008;
    text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff;
  }
  .trif-slide-8 {
    /* background: linear-gradient(#010001, #F5F6F6, #010001, #F5F6F6, #010001, #F5F6F6, #010001); */
    /* background: linear-gradient(to bottom, #010001 0%, #010001 14.28%, #F5F6F6 14.28%, #F5F6F6 28.56%, #010001 28.56%, #010001 42.84%, #F5F6F6 42.84%, #F5F6F6 57.12%, #010001 57.12%, #010001 71.4%, #F5F6F6 71.4%, #F5F6F6 85.68%, #010001 85.68%, #010001 100%); */
    background: linear-gradient(to right, #010001 0%, #010001 50%, #f4bc4c 50%, #f4bc4c 100%);
  }
  .trif-slide-8::before {
    content: "8";
    margin: auto;
    color: #E90008;
    text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff;
  }
  .trifectaTab-contentBlock {
    width: 100%;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
    -webkit-transition: .3s ease;
    -o-transition: .3s ease;
    transition: .3s ease;
    position: relative;
    overflow: hidden;
  }
  .trifectaTab-contentBlock-scroll {
    width: 100%;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
    -webkit-transition: .3s ease;
    -o-transition: .3s ease;
    transition: .3s ease;
    position: relative;
  }
  .trifectaTab-table {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(7, 1fr);
    grid-gap: 10px;
    padding: 0 10px;
    width: 100%;
    height: 287px;
  }
  .dogs-section {
    height: 287px;
  }
  .roulette-btn-base.selected {
    color: #fff;
    text-shadow: 0 -1px 0 rgb(0 0 0 / 25%);
    background-color: #faa732;
    background-image: linear-gradient(180deg, #fbb450, #f89406);
    background-repeat: repeat-x;
    border-color: #f89406 #f89406 #ad6704;
    border-color: rgba(0, 0, 0, .1) rgba(0, 0, 0, .1) rgba(0, 0, 0, .25);
  }
</style>